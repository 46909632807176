import { ApolloProvider } from '@apollo/client';
import { Toast } from '@hexa-ui/components';
import FilterContextProvider from 'components/FilterBar/Filter/FilterContext';
import Loading from 'components/Loading/Loading';
import { AdaptersProvider } from 'contexts/AdaptersContext';
import { useConfiguredApolloClient } from 'core/hooks';
import { lazy, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, Route, Routes } from 'react-router-dom';
import { isFeatureEnabled } from 'utils/featureToggles';

export const BASE_PATH = '/bees-gateway-web';
export const REPORTS_PATH = `${BASE_PATH}/payments`;
export const TRANSACTIONS_PATH = `${BASE_PATH}/transactions`;
export const WORKFLOW_PATH = `${BASE_PATH}/workflow`;
export const CREATE_WORKFLOW_PATH = `${BASE_PATH}/workflow/create`;

const Base = lazy(() => import('../components/AuthLoadingWrapper'));
const Workflow = lazy(() => import('../pages/Workflow'));
const CreateWorkflow = lazy(() => import('../pages/CreateWorkflowPage'));
const Reports = lazy(() => import('../pages/Reports'));
const RequestPage = lazy(() => import('../pages/Reports/RequestPage'));

const withAdaptersProvider = (Component) => (props) =>
  (
    <AdaptersProvider>
      <Component {...props} />
    </AdaptersProvider>
  );

const CreateWorkflowWithProvider = withAdaptersProvider(CreateWorkflow);

export const AppRoutes = (): JSX.Element => {
  const client = useConfiguredApolloClient();

  return (
    <ErrorBoundary fallbackRender={({ error }) => <div>An error occurred: {error.message}</div>}>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path={BASE_PATH} element={<Navigate to={REPORTS_PATH} replace />} />

          <Route path={BASE_PATH} element={<Base />}>
            <Route path={REPORTS_PATH} element={<Reports />} />
            <Route
              path={TRANSACTIONS_PATH}
              element={
                <ApolloProvider client={client}>
                  <Toast.Provider duration={5000} swipeDirection="right">
                    <FilterContextProvider>
                      <RequestPage />
                    </FilterContextProvider>
                  </Toast.Provider>
                </ApolloProvider>
              }
            />
            {isFeatureEnabled('workflow') && (
              <>
                <Route path={WORKFLOW_PATH} element={<Workflow />} />
                <Route path={CREATE_WORKFLOW_PATH} element={<CreateWorkflowWithProvider />} />
              </>
            )}
            <Route path="*" element={<Navigate to="/404" replace />} />
          </Route>
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
};

export default AppRoutes;
