import { IAdapter } from 'types/adapter';
import { ApiInstance } from 'utils/api/ApiInstance';

export const getAdapters = async (URL: string, country: string): Promise<IAdapter[]> => {
  if (!country) {
    throw new Error('Country parameter is required');
  }

  const api = ApiInstance(URL, country);

  try {
    const response = await api.get('/fintech-payment-configurator-service/v1/adapters');
    return response.data;
  } catch (error) {
    console.error('Error fetching adapters:', error);
    throw error;
  }
};
